import React, { useEffect, useContext, useState } from 'react';  
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'; 

import UserContext from './contexts/User';

import './scss/fontawesome.css';
import './scss/app.scss';

const Loading = () => {
  return (
    <span>Loading...</span>
  )
};

const Header = React.lazy(() => import('./views/partials/Header'));
const Footer = React.lazy(() => import('./views/partials/Footer'));

const Index = React.lazy(() => import('./views/pages/Index'));
const Login = React.lazy(() => import('./views/pages/Login'));
const Register = React.lazy(() => import('./views/pages/Register'));
const Cart = React.lazy(() => import('./views/pages/Cart'));
const UserOrders = React.lazy(() => import('./views/pages/UserOrders'));
const OrderAwait = React.lazy(() => import('./views/pages/OrderAwait'));

const App = () => {
  const [user, setUser] = useState(false);

  return (
    <BrowserRouter>
      <React.Fragment>
        <UserContext.Provider value={{ user, setUser }}>
          <Header />
          <div className='page__wrapper'>
            <React.Suspense fallback={<Loading />}>
              <Routes>
                <Route path="/" element={<Index/>} />
                <Route path="/user/login" element={<Login/>} />
                <Route path="/user/register" element={<Register/>} />
                <Route path="/cart" element={<Cart/>} />

                <Route path="/user/orders" element={<UserOrders/>} />
                <Route path="/orders/:orderid" element={<OrderAwait/>} />

                <Route path="*" element={<Navigate to='/' />} />
              </Routes>
            </React.Suspense>
          </div>
          <Footer />
        </UserContext.Provider>
      </React.Fragment>
    </BrowserRouter>
  )
};

export default App;